<script setup lang="ts">
import { PersonalApi } from '~/net/apis/personal_api'
import { cryptoUtils } from '~/utils/crypto/core'

const router = useRouter()
const onClickLeft = () => history.back()

const actions = ref<{ name: string;value: string | null }[]>([])
const show = ref(false)
const type = ref('全部类型')
const payType = ref(null)
const timeCate = ref(0)
const orderStatus = ref(0)
// const list = ref<any[]>()
function onSelect(item: any) {
  // 默认情况下点击选项时不会自动收起
  // 可以通过 close-on-click-action 属性开启自动收起
  show.value = false
  type.value = item.name
  payType.value = item.value
}
const actionsStatus = [
  { name: '全部状态', value: 0 },
  { name: '待支付', value: 1 },
  { name: '成功', value: 3 },
  { name: '失败', value: 4 }
]
const showStatus = ref(false)
const status = ref('全部状态')
function onSelectStatus(item: any) {
  // 默认情况下点击选项时不会自动收起
  // 可以通过 close-on-click-action 属性开启自动收起
  showStatus.value = false
  status.value = item.name
  orderStatus.value = item.value
}
const actionsDate = [
  { name: '最近1周', value: 0 },
  { name: '最近1月', value: 1 },
  { name: '最近3月', value: 2 },
  { name: '最近半年', value: 3 }
]
const showDate = ref(false)
const date = ref('最近1月')
function onSelect1(item: any) {
  // 默认情况下点击选项时不会自动收起
  // 可以通过 close-on-click-action 属性开启自动收起
  showDate.value = false
  date.value = item.name
  timeCate.value = item.value
}
async function getPayType() {
  const { data } = await PersonalApi.getJavPayType()
  actions.value = Object.entries(data).map(([key, value]) => ({
    name: value,
    value: key
  }))
  actions.value.unshift({ name: '全部类型', value: null })
}
getPayType()

function goDetail(item: any) {
  router.push({ name: 'flowDetail', query: { params: cryptoUtils.encrypt({ ...item }, 'flowDetail') } })
  window.localStorage.setItem('flowDetail', JSON.stringify({ ...item }))
}
</script>

<template>
  <div h-screen>
    <van-nav-bar title="充值记录" left-arrow @click-left="onClickLeft" />
    <div>
      <div h-10 flex flex-row ct-d0d0d0>
        <div flex flex-1 items-center justify-center @click="show = true">
          {{ type }}
          <van-icon :name="show ? 'arrow-up' : 'arrow-down'" ml-2 />
        </div>
        <div flex flex-1 items-center justify-center @click="showStatus = true">
          {{ status }}
          <van-icon :name="showStatus ? 'arrow-up' : 'arrow-down'" ml-2 />
        </div>
        <div flex flex-1 items-center justify-center @click="showDate = true">
          {{ date }}
          <van-icon :name="showDate ? 'arrow-up' : 'arrow-down'" ml-2 />
        </div>
      </div>
    </div>
    <BasePullRefreshList
      wrap-class="w-full grid grid-cols-1 gap2 px-15px"
      :request-api="PersonalApi.orderRecord"
      :offset-list="20"
      :request-params="{
        pageNum: 1,
        pageSize: 16,
        orderStatus,
        payType,
        timeCate
      }"
    >
      <template #="{ data }">
        <OrderRecord
        :item="data as OrderRecord"
        :pay-type="(actions.find((item) => item.value === String(data.payType)) || {}).name"
        @click="goDetail(data)" />
      </template>
    </BasePullRefreshList>

    <van-action-sheet
      v-model:show="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="show = false"
      @select="onSelect"
    />
    <van-action-sheet
      v-model:show="showStatus"
      :actions="actionsStatus"
      cancel-text="取消"
      close-on-click-action
      @cancel="showStatus = false"
      @select="onSelectStatus"
    />
    <van-action-sheet
      v-model:show="showDate"
      :actions="actionsDate"
      cancel-text="取消"
      close-on-click-action
      @cancel="showDate = false"
      @select="onSelect1"
    />
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
.bord {
  border-bottom: 1px solid #41466040;
}
:deep(.van-action-sheet__item) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__cancel) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__gap) {
  background: #414660;
}
:deep(.van-action-sheet__item:hover) {
  color: #fd5f03;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
</style>
