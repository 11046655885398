<script setup lang="ts">
import icon_alipay from '~/assets/image/vip/icon_alipay@2x.png'
import icon_wechat from '~/assets/image/vip/icon_wechat@2x.png'
import icon_usdt from '~/assets/image/vip/icon_usdt@2x.png'
import icon_bank from '~/assets/image/vip/icon_bank@2x.png'
import icon_other from '~/assets/image/vip/icon_pay_other@2x.png'
import type { ORDER_STATUS } from '~/enums/order_status'
import { label } from '~/enums/order_status'

withDefaults(
  defineProps<{
    item: OrderRecord
    payType?: string
  }>(),
  {}
)
const buyImgs = new Map([
  [0, icon_alipay],
  [1, icon_wechat],
  [2, icon_bank],
  [3, icon_usdt],
  [4, icon_other]
])
</script>

<template>
  <div pos-relative>
    <div mb-4 mt-4 flex flex-row pb-2 class="bord">
      <img :src="buyImgs.get(item?.bonusType || 4)" m-4 mt-2 h-12 w-12 />
      <div w-76>
        <div flex justify-between>
          <span text-lg ct-e8e8e8>{{ payType || '-' }}</span>
          <span text-sm ct-e8e8e8>+{{ item.orderAmount }}</span>
        </div>
        <div flex items-center justify-between>
          <span text-xs ct-5c5e69>{{ item.eventTime }}</span>
          <span text-xs ct-e8e8e8>{{ label(item?.orderStatus as ORDER_STATUS) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
